import { authRoles } from './auth/authRoles'


export const navigations = [
    {
        name: 'Dashboard',
        path: '/dashboard/default',
        icon: 'dashboard',
    },
    {
        name: 'Members',
        icon: 'people',
        auth:authRoles.admin,
        children: [
            {
                name: 'New Member',
                path: '/pages/new-customer/new',
                iconText: 'NC',
            },
            {
                name: 'All Member List',
                path: '/pages/customer-list',
                iconText: 'CL',
                
            },
            {
                name: 'Approved Member',
                path: '/pages/approved-customer',
                iconText: 'NC',
            },
            {
                name: 'Member Awaiting Approval',
                path: '/pages/await-approval',
                iconText: 'NC',
            },
            {
                name:"Member Awaiting Photo",
                path:'pages/await-photo',
                iconText:'NC'
            }
        ],
    },
    // {
    //     name:"Notice Board",
    //     icon:"notification_important",
    //     auth:authRoles.admin,
    //     path:'/pages/notice-board'
    // },
    {
        name:"Events",
        icon:"event",
        auth:authRoles.admin,
        path:'/pages/events'
    }
]

export const navigationSA = [
    {
        name: 'Dashboard',
        path: '/dashboard/analytics',
        icon: 'analytics',
    },
    {
        name: 'Clients',
        icon: 'people',
        auth:authRoles.sa,
        children: [
            {
                name: 'New client',
                path: '/pages/new-client/new',
                iconText: 'NC',
            },
            {
                name: 'All Client List',
                path: '/pages/client-list',
                iconText: 'CL',
                
            },
        ],
    },
    {
        name: 'MembershipTypes',
        icon: 'wallet',
        auth:authRoles.sa,
        children: [
            {
                name: 'New Membership Type',
                path: '/pages/membership-type/new',
                iconText: 'NC',
            },
            {
                name: 'All Membership Type List',
                path: '/pages/membership-type-list',
                iconText: 'CL',
                
            },
        ],
    },
    {
        name: 'Cards',
        icon: 'badge',
        auth:authRoles.admin,
        children: [
            {
                name: 'Add New Card',
                path: '/pages/card/new',
                iconText: 'NC',
            },
            {
                name: 'All Card Lists',
                path: '/pages/all-cards',
                iconText: 'CL'
            }
        ],
    },
    {
        name: 'Wallet Passes',
        icon: 'wallet',
        auth:authRoles.admin,
        children: [
            {
                name: 'Add New Wallet Pass',
                path: '/pages/wallet-pass/new',
                iconText: 'NC',
            },
            {
                name: 'All Wallet passes',
                path: '/pages/all-wallet-pass',
                iconText: 'CL'
            }
        ],
    },
    {
        name: 'Seasons',
        icon: 'wallet',
        auth:authRoles.admin,
        children: [
            {
                name: 'Add New Season',
                path: '/pages/race-season/new',
                iconText: 'NC',
            },
            {
                name: 'All Seasons',
                path: '/pages/all-seasons',
                iconText: 'CL'
            }
        ],
    }
]
  

